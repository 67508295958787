import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'




const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 &:last-child{border-bottom:0; padding-bottom:0;}
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:italic;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function VehicleGPSTrackingPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="GPS Tracking Technology and Systems for Vehicles and Assets"
        description="Manage your fleet and track your vehicles and save logistics costs in your business with WYN Technologies' GPS tracking technology. Contact us for reliable and effective solutions."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Vehicle GPS Tracking Systems</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Vehicle GPS Tracking Systems</GroceryTitle>

              <p>Are you trying to run a business that requires work trucks or delivery services? If so, WYN Technologies is here to take your business to the next level. We offer premium vehicle GPS tracking solutions in several different formats. Our remote tracking systems can make your life a lot easier whenever you have a fleet of trucks to manage.</p>

              <p>We’re sure you have questions about how to choose the right GPS fleet tracking solution for you and your company. That’s why we put together this article to answer some of the questions we’re asked most frequently. At WYN, we’re here to help, and one of our primary goals is to help your business run more smoothly and ultimately become more successful! </p>

            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide with Our GPS Vehicle Tracking System Services</GroceryIndustryTitle>

          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Free, No-Obligation Product Demos</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Perhaps you’ve observed vehicle tracking systems being used by another business or service provider, but you’ve not had the chance to try them out firsthand and experience how they work for yourself. Not a problem – WYN would be happy to give you that opportunity! If you’re new to GPS tracking systems, or would just like to learn more about all the potential applications and benefits, we can give you a complete product demo. We’ll show you what a system like this can deliver, and we can help you understand how your business can take full advantage. We’re here to help!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Are you in the transportation, distribution, or warehousing business? WYN can help by offering you the right GPS tracking products for your needs. Plus, we’re much more than simply product vendors; we’re really about providing complete solutions! When you partner with us, you can rest a little easier knowing that we can help take care of everything from installation to implementation, start to finish. And we won’t leave you guessing about how to use your new technology, either. We’ll be glad to walk you and your team through comprehensive product orientation and training. We’re happy to answer your questions along the way, too!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Ongoing Support Whenever You May Need It</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Ever had a commercial technology provider leave you feeling all alone and on your own once your installation was complete? Hey, we’ve been there too, and we know how that feels on the receiving end. Thankfully, you won’t have to experience that unpleasant feeling whenever you partner with us at WYN Technologies! Our goal is to be here for you whenever you may need us. We also offer some convenient “service after the sale” contracts, if you’d prefer for us to keep your equipment maintained for you. Just give us a call anytime you have a question or issue!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>

            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Access Control Basics</h2> */}
              </MarkdownContentTitle>
              <MarkdownContent>
                <h2>How Do Vehicle Tracking Devices Work?</h2>
                <p>While most of us are somewhat familiar with GPS in general, it may still be beneficial to look closer at how vehicle tracking solutions based on GPS technology work. As you may know, GPS is short for Global Positioning System. This system works by using a network of satellites to identify an object’s location on Earth. If you have a GPS real-time tracker mounted in your vehicle, then the satellites can be used to pinpoint your exact location, and can also be utilized to help provide you with directions to your next destination. It’s really as simple as that!
</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Here’s Why You Should Take Advantage of a Commercial Vehicle GPS Tracking System</h2>
                <p>GPS systems for cars or trucks are helpful in terms of navigating new places or figuring out ETAs, but they can be used for so much more. Vehicle tracking systems are great to modernize and optimize your business. In fact, the more vehicles you have to manage, the more you really need a GPS real-time tracker; it will improve your company in four major ways.</p>
                <ul>
                  <li>
                    <strong>You’ll Save Time</strong>
                    <p>Automobile GPS systems are a great way to optimize your transportation logistics and save time. For example, GPS systems are often able to alert you about traffic jams and suggest alternate routes to avoid congested traffic. Using these suggestions will help get your drivers and their cargo where they need to be as quickly as possible.</p>
                  </li>
                  <li>
                    <strong>You’ll Save Money</strong>
                    <p>Vehicle tracking solutions like GPS system are a great way to save money. Optimized routes mean that your drivers will be on the road less time, meaning less fuel needed and less wear-and-tear on your fleet vehicles. In other words, you’ll be saving on costs in more ways than one!</p>
                  </li>
                  <li>
                    <strong>You’ll Increase Security</strong>
                    <p>A truck GPS tracker is a perfect way to give yourself peace of mind. If one of your trucks ever goes missing, you will be able to see where it is and locate it as soon as possible. Some commercial GPS systems even give you the option to be alerted if a truck is used during its designated “off” hours.</p>
                  </li>
                  <li>
                    <strong>You’ll Increase Customer Satisfaction</strong>
                    <p>In a society like ours which has come to expect instant gratification, people like to know where their packages are and when they’ll arrive. In fact, some people even decide where to shop and which company to partner with based upon the tracking and shipping information they’re able to provide. GPS fleet tracking is a great way to keep your customers and clients updated about where your truck is, and when it’s estimated to arrive at their location.</p>
                  </li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Knowing What You Need </h2>
                <p>To ensure that your business is running as smoothly as possible, you need to choose the vehicle tracking system that makes the most sense for you. There are lots of options out there, and they’re not one-size-fits-all. Here at WYN Technologies, we suggest that you ask yourself questions like these whenever considering a new GPS tracking system:</p>
                <ul>
                  <li>Do you need real-time tracking versus a legacy, ping-based system?</li>
                  <li>How easy will the system be for your current employees to learn and use?</li>
                  <li>Will you need 24/7 customer service from your GPS provider?</li>
                  <li>How easy (or how involved) is the installation and setup process?</li>
                  <li>Supports modern wired and wireless technologies, including both mobile and cloud-based access</li>
                  <li>Supports multiple access authorization options like keypads, fobs, mobile apps, biometrics, and two-factor authentication</li>
                  <li>Will you have any additional staffing concerns where your new GPS tracking system is concerned?</li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>We Offer More Than Just Basic GPS Tracking</h2>
                <p>There are several good commercial GPS tracking applications, and the ones we offer at WYN Technologies give you some valuable multipurpose application options. Here are four distinct GPS-based services to consider which can make your business run more smoothly, and can help you in other ways as well.</p>

                <ul>
                  <li>
                    <strong>Dashcams</strong>
                    <p>Dashcams are a great way to help keep everyone on their best behavior, and also protect yourself if your vehicles were ever in an accident. Our dashcams can be positioned to record both inside the vehicle’s cab, as well as outside the vehicle.</p>
                  </li>
                  <li>
                    <strong>Asset Tracking</strong>
                    <p>Much of what we’re exploring in this article revolves around fleet tracking; in fact, this is the most popular type of GPS tracking system we install at WYN. These systems help you manage your fleet and optimize your routes to save both time and money.</p>
                  </li>
                  <li>
                    <strong>Fleet Tracking</strong>
                    <p>Much of what we’re exploring in this article revolves around fleet tracking; in fact, this is the most popular type of GPS tracking system we install at WYN. These systems help you manage your fleet and optimize your routes to save both time and money.</p>
                  </li>
                  <li>
                    <strong>Teen Tracking</strong>
                    <p>Having a new driver in the house can be a little unsettling, and can certainly increase your anxiety level as a parent. We understand, and that’s why we’re also happy to offer some good teen driver tracking technologies. For example, you can be notified anytime your teen driver exceeds a speed limit threshold, or whenever they may venture into a location that you haven’t specifically approved.</p>
                  </li>
                </ul>
                </MarkdownContent>               
                <MarkdownContent>    
                    <h2>Make Life Better with WYN Technologies</h2>
                  <p>GPS systems from WYN Technologies can help take your business to the next level, and can be used to improve your own personal life, too.</p>
                    <p>
                At WYN, we’re here to help by providing you with Whatever You Need to make your business or life better! You can trust us to take care of you the right way. How? Through our commitment to prioritize clear communication, high moral standards, and innovation in everything we do. See what WYN Technologies can do for you by calling  <a href="tel:3368990555">(336) 899-0555</a> today!
                </p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default VehicleGPSTrackingPage
